import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import {
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden,
  BackgroundShadow1,
  BackgroundShadow2,
  TitleWrapper,
  NumberWrapper,
  ImageWrapper,
} from './pillar.style';
import ProductManagement from 'common/src/assets/image/saas/product_management_card.png';
import EngagamentCard from 'common/src/assets/image/saas/sequence_card.png';
import AnalyseManagement from 'common/src/assets/image/saas/analyse_card.png';

import BackgroundBottom from 'common/src/assets/image/saas/background.png';

const images = [ProductManagement, EngagamentCard, AnalyseManagement];

const TimelineSection = ({
  sectionWrapper,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  row,
  col,
  button,
  textArea,
  imageArea,
  imageTwo,
  buttonWrapper,
  indexStyle,
  timelineTitle,
  timelineDescription,
  imageBackground,
  activeImage,
}) => {
  const [imageView, setImageView] = useState(0);
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        PILLARS {
          title
          secondaryText
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} id="threePillars">
      <BackgroundShadow1 />
      <BackgroundShadow2 />
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} content="THREE PILLARS OF SALESOCEAN" />
          <Heading
            {...sectionTitle}
            content="Our solution is build on three pillars for success"
          />
        </Box>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <Fade bottom>
              <div>
                {images.map((image, index) => {
                  if (index !== imageView) return '';
                  return (
                    <div
                      class="fadeInCustom"
                      style={{ zIndex: 0, position: 'relative' }}
                    >
                      <Image
                        {...imageTwo}
                        src={image}
                        alt="Illustration"
                        // className={`${index === imageView ? 'active' : ''}`}
                      />
                    </div>
                  );
                })}

                <Image
                  {...imageBackground}
                  src={BackgroundBottom}
                  alt="Illustration"
                />
              </div>
            </Fade>
          </Box>
          <Box {...col} {...textArea}>
            <TimelineWrapper>
              {Data.saasJson.PILLARS.map((item, index) => (
                <TimelineItem
                  key={`timeline-item-${index}`}
                  onMouseEnter={() => {
                    setImageView(index);
                  }}
                >
                  <TimelineIndex>
                    <Hidden>
                      <Slide bottom>
                        <NumberWrapper>
                          <Text
                            as="span"
                            content={item.index || `0${index + 1}`}
                            {...indexStyle}
                          />
                        </NumberWrapper>
                      </Slide>
                    </Hidden>
                  </TimelineIndex>
                  <TimelineContent>
                    <Hidden>
                      <Slide bottom delay={100}>
                        <TitleWrapper>
                          <Heading
                            as="h2"
                            content={item.title}
                            {...timelineTitle}
                          />
                        </TitleWrapper>
                      </Slide>
                    </Hidden>
                    <Hidden>
                      <Slide bottom delay={200}>
                        <Text
                          content={item.secondaryText}
                          {...timelineDescription}
                        />
                      </Slide>
                    </Hidden>
                  </TimelineContent>
                  <TimelineDot
                    class={index === imageView ? 'activeDot' : 'inactiveDot'}
                  />
                </TimelineItem>
              ))}
            </TimelineWrapper>
          </Box>
        </Box>
        {/* <Box {...buttonWrapper}>
          <a href="#1">
            <Button {...button} onClick={() => {}} title="GET DEMO" />
          </a>
        </Box> */}
      </Container>
    </Box>
  );
};

TimelineSection.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object,
};

TimelineSection.defaultProps = {
  sectionWrapper: {
    style: { background: 'rgb(46, 55, 76)', overflow: 'hidden' },
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
  }, // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: 'white',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '55%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '45%'],
    mb: ['30px', '40px', '40px', '0', '0'],
  },
  title: {
    fontSize: ['30px', '38px', '38px', '48px', '48px'],
    fontWeight: '300',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
  },

  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  imageOne: {
    mb: '40px',
    ml: 'auto',
    mr: 'auto',
    zIndex: 100,
    position: 'relative',
  },
  imageTwo: {
    ml: 'auto',
    mr: 'auto',
    height: '600px',
    style: {
      zIndex: 100,
      position: 'relative',
      transform: 'height 0',
      transition: '-webkit-transform 0.6s ease',
      transition: 'transform 0.6s ease',
      transition: 'transform 0.6s ease, -webkit-transform 0.6s ease',
    },
  },
  imageBackground: {
    ml: 'auto',
    mr: 'auto',
    width: '365px',
    marginTop: '-25.3rem',
    style: {
      zIndex: 0,
    },
  },
  activeImage: {
    ml: 'auto',
    mr: 'auto',
    height: '600px',
    style: {
      zIndex: 100,
      position: 'relative',
      transform: 'translateY(0)',
    },
  },
  indexStyle: {
    fontSize: ['36px', '42px', '52px', '56px', '72px'],
    fontWeight: '300',
    display: 'block',
    lineHeight: '1',
    mb: '0',
  },
  timelineTitle: {
    fontSize: ['16px', '17px', '18px', '18px', '20px'],
    fontWeight: '500',
    letterSpacing: '-0.025em',
    mb: '13px',
  },
  timelineDescription: {
    fontSize: ['14px', '15px', '15px', '15px', '16px'],
    lineHeight: '2',
    color: '#b1afaf',
    mb: '0',
  },
};

export default TimelineSection;
