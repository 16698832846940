import styled from 'styled-components';

export const TimelineWrapper = styled.div``;

export const ImageWrapper = styled.div`
  transform: translateY(-1.25em);
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  &: .active {
    transform: translateY(0);
  }
`;

export const TimelineDot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  box-shadow: 0px 3px 8.46px 0.54px rgba(23, 65, 104, 0.2);
  z-index: 1;
  background: #fff;
  transform: translateY(-50%);

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ebedf5;
    transition: 0.25s ease-in-out;
  }
  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    &:after {
      width: 10px;
      height: 10px;
    }
  }
`;

export const TitleWrapper = styled.div`
  color: #ebedf5;
`;

export const BackgroundShadow1 = styled.div`
  position: absolute !important;
  top: 43rem;
  right: 0rem;
  width: 125rem;
  z-index: 0;
  height: 29rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(185deg);
`;

export const BackgroundShadow2 = styled.div`
  position: absolute !important;
  top: 40rem;
  right: 0rem;
  width: 125rem;
  z-index: 0;
  height: 29rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(185deg);
`;

export const NumberWrapper = styled.div`
  color: #eaebec;
`;

export const TimelineItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 90px;
  padding-left: 50px;
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:last-child {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  }

  &:hover {
    ${TimelineDot} {
      &:after {
        background: #2962ff;
      }
    }
    ${TitleWrapper} {
      color: white;
    }
    ${NumberWrapper} {
      color: white;
    }
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    height: calc(50% + 45px);
    width: 2px;
    background: #f0f0f1;
    left: 9px;
    @media (max-width: 480px) {
      left: 7px;
    }
  }

  &:before {
    bottom: 50%;
  }

  &:after {
    top: 50%;
  }

  @media (max-width: 990px) {
    margin-bottom: 60px;
  }
  @media (max-width: 480px) {
    padding-left: 30px;
  }
`;

export const TimelineIndex = styled.div``;

export const TimelineContent = styled.div`
  margin-left: 30px;
  @media (max-width: 480px) {
    margin-left: 20px;
  }
`;

export const Hidden = styled.div`
  overflow: hidden;
`;
